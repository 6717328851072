
import { Dungeon, Room } from '@/models';
import router, { HomeRoute } from '@/router';
import { DungeonService } from '@/services';
import { onMounted, defineComponent, ref, computed, reactive } from 'vue';
import { useRoute } from 'vue-router';

interface InventoryPositionCalculation {
	anchor: number;
	mousePosition: number;
	storedPosition: number;
}

export default defineComponent({
	setup() {
		const invPositionCalc = reactive<InventoryPositionCalculation>({
			anchor: null,
			mousePosition: null,
			storedPosition: window.innerHeight / 2
		});
		const inventory = ref();
		const route = useRoute();
		const dungeon = ref<Dungeon>(null);
		const mapOpen = ref(false);
		const currentRoom = ref<Room>(null);
		const roomHistory = reactive<number[]>([]);

		const getRoom = (id: number) => dungeon.value?.rooms.find(e => e.id === id);

		const proceedTo = (id: number) => {
			if (currentRoom.value) roomHistory.push(currentRoom.value.id);
			currentRoom.value = getRoom(id);
		};

		const goBack = () => {
			const id = roomHistory.pop();
			currentRoom.value = getRoom(id);
		};

		const returnRoom = computed<Room>(() => {
			if (!roomHistory.length) return null;

			return getRoom(roomHistory[roomHistory.length - 1]);
		});

		const nextRooms = computed<Room[]>(() => {
			const exits = currentRoom.value.exits;
			if (!exits.length) return null;

			return exits.map(e => getRoom(e));
		});

		const inventoryPosition = computed<number>(() => {
			if (invPositionCalc.anchor === null)
				return invPositionCalc.storedPosition;

			const diff = invPositionCalc.mousePosition - invPositionCalc.anchor;

			const target = invPositionCalc.storedPosition + diff;

			const max = window.innerHeight - inventory.value.offsetHeight;

			return Math.max(Math.min(target, max), 0);
		});

		const exitDungeon = () => router.push({ name: HomeRoute });

		const startDrag = (e: MouseEvent | TouchEvent) => {
			const pos = e instanceof MouseEvent ? e.clientY : e.touches[0].screenY;
			invPositionCalc.mousePosition = pos;
			invPositionCalc.anchor = pos;
			document.body.classList.add('disable-refresh');
		};

		const endDrag = () => {
			invPositionCalc.storedPosition = inventoryPosition.value;
			invPositionCalc.anchor = null;
			document.body.classList.remove('disable-refresh');
		};

		const mouseMove = (e: MouseEvent | TouchEvent) => {
			invPositionCalc.mousePosition =
				e instanceof MouseEvent ? e.clientY : e.touches[0].screenY;
		};

		onMounted(() => {
			const slug = route.params.slug as string;
			dungeon.value = DungeonService.get(slug);

			if (!dungeon.value) {
				exitDungeon();
				return;
			}

			const entranceId = dungeon.value.entrances[0];

			proceedTo(entranceId);
		});

		return {
			dungeon,
			mapOpen,

			currentRoom,
			returnRoom,
			nextRooms,
			proceedTo,
			goBack,
			exitDungeon,

			inventory,
			inventoryPosition,
			startDrag,
			endDrag,
			mouseMove
		};
	}
});
